<template>
  <v-container fluid class="px-1 pa-sm-5">
    <feed :posts="projects.list" v-if="projects" :isGuest="isGuest" />
    <div class="skeleton-loaders">
      <skeleton @triggerIntersected="showMore" v-show="!$apollo.queries.projects.loading && !allFetched" :loaders="1"/>
      <skeleton v-show="$apollo.queries.projects.loading && !allFetched" :loaders="1" />
    </div>
    <div v-if="allFetched && projects.list.length === 0" class="text-center">
      <h3 class="text-center mb-5">😐 Sorry, we can't find any projects with these filters.</h3>
      <v-avatar size="320" max-width="100%" rounded>
        <v-img :src="require('@/assets/images/sorry.gif')" />
      </v-avatar>
    </div>
  </v-container>
</template>

<script>
import Feed from '../components/Feed'
import Skeleton from '../components/Utils/Skeleton'
import PROJECTS from '../graphql/projects.graphql'

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      page: 1,
      number: 7,
      categories: [],
      type: [],
      allFetched: false
    }
  },
  async activated () {
    try {
      if (this.shouldFetchFeed) {
        this.refetch = true
        await this.$store.dispatch('setFetchFeed', false)
        await this.$apollo.queries.projects.refetch()
      }
    } catch (e) {
      return e
    } finally {
      this.refetch = false
    }
  },
  apollo: {
    projects: {
      query: PROJECTS,
      variables () {
        return {
          paged: 1,
          number: this.number,
          type: this.type,
          categories: this.categories
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    shouldFetchFeed () {
      return this.$store.state.fetchFeed
    },
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  },
  methods: {
    async showMore () {
      this.page++
      // Fetch more data and transform the original result
      try {
        const res = await this.$apollo.queries.projects.fetchMore({
          // New variables
          variables: {
            paged: this.page,
            number: this.number,
            type: this.type,
            categories: this.categories
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              projects: {
                __typename: previousResult.projects.__typename,
                // Merging the list
                list: [...previousResult.projects.list, ...fetchMoreResult.projects.list]
              }
              // meta: {
              //   __typename: previousResult.meta.__typename,
              //   // Merging the tag list
              //   list: [...fetchMoreResult.meta.list]
              // }
            }
          }
        })
        this.allFetched = res.data.projects.list.length === 0
      } catch (e) {
        console.log(e)
        return e
      }
    },
    handleFilters (filters) {
      this.projects = []
      this.page = 1
      this.allFetched = false
      this.categories = filters.categories
      this.type = filters.tags
    }
  },
  metaInfo () {
    return {
      title: 'Feed | Gradbee'
    }
  },
  components: {
    Feed,
    Skeleton
  }
}
</script>

<style scoped>

</style>
